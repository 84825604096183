import Typography from 'typography';
import Colors from './colors';

const europaFontFamily = [
    'europa',
    'Helvetica',
    'Arial',
    'sans-serif',
];

const typography = new Typography(
    {
        baseFontSize: '20px',
        baseLineHeight: '30px',
        scaleRatio: 2,
        headerFontFamily: europaFontFamily,
        bodyFontFamily: europaFontFamily,
        // bodyWeight: 300,
        overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => ({
            'body': {
                color: Colors.darkText,
            },
            'h1': {
                fontSize: '32px',
                lineHeight: '40px',
                whiteSpace: 'pre-line',  // Preserve newlines from markdown frontmatter
            },
            'h2,h3,h4,h5,h6': {
                ...adjustFontSizeTo('20px'),
                lineHeight: rhythm(1),
                whiteSpace: 'pre-line',
                marginBottom: '20px',
            },
            'p':{
                maxWidth: '550px',
            },
            'small,.small,figcaption': {
                ...adjustFontSizeTo('16px'),
                lineHeight: '20px',
                maxWidth: '500px',
            },
            'li': {
                marginBottom: '0',
            },
            'blockquote': {
                fontStyle: 'italic',
                color: Colors.midText
            }
        }),
    });

const { rhythm, scale } = typography;

export { rhythm, scale, typography as default };