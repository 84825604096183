import React from 'react';

const SubscribeFormIntro = () => (
  <>
    <h2>Get the Webologists' Journal</h2>
    <p className="small">
      We send out a newsletter every now and then to showcase recent projects
      and news articles. Your email is kept in an isolated vacuum chamber:
    </p>
  </>
);

export default SubscribeFormIntro;
